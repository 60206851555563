a {
  color: #364050;
}
a:hover {
  text-decoration: none;
  color: #19181f;
}

input.form-control.form-control-sm {
  background-image: url(http://localhost:3011/images/search.png);
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 8px center;
  padding: 0 10px 0 28px;
}

.doc_action_button {
  margin-left: -86px;
  visibility: hidden;
}

img:hover + .doc_action_button,
.doc_action_button:hover {
  visibility: visible;
}

.link, .link:hover {
  color: #4B49AC;
}

.image {
  background: #DAE7FF;
  border-radius: 4px;
  padding-left: 23px;
}

.dashbord-card {
  padding: 20px 20px;
}

.leave-badge {
  border-radius: 20px;
  font-weight: normal;
}

.leave-badge-warning {
  border: 1px solid #FFC100;
  background: none;
  color: #FFC100;

}

.leave-badge-success {
  border: 1px solid #57B657;
  background: none;
  color: #57B657;
}

.leave-badge-danger {
  border: 1px solid #FF4747;
  background: none;
  color: #FF4747;
}

.dashboard-box {
  width: 100%;
  height:150px;
  background: #7DA0FA;
  color: #FFF;
  padding: 20px 20px;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
}

.summary-box {
  width: 100%;
  height:150px;
  background: #7DA0FA;
  color: #FFF;
  padding: 20px 20px;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
}

.notifications {
  width: 100%;
  min-height: 485px;
}

.filter-button {
  color: #616173 !important;
}

.filter-button:hover {
  background: #DAE7FF !important;
}

.dashboard-count {
  font-size: 30px;
  text-align: left;
}

.dashboard-label {
  font-size: 14px;
  text-align: left;
}

.joinees {
  background-color: #5d88f7;
}

.interviews {
  background-color: #3858a8;
}

.followup {
  background-color: #445685;
}

.termination {
  background-color: #F3797E;
}

.joinees .dropdown {
  margin-left: 180px;
}

.interviews .dropdown {
  margin-left: 180px;
}

.followup .dropdown {
  margin-left: 180px;
}

.termination .dropdown {
  margin-left: 180px;
}

.dropdown .btn {
  border: 1px solid transparent !important;
}

.leavetype1 {
  background-color: #698be4;
  color: #FFF;
}

.leavetype2 {
  background-color: #4a6bb8;
  color: #FFF;
}

.leave-count {
  font-size: 18px;
}

.leaves .dropdown {
  margin-left: 90px;
}

.dashboard-header {
  border-bottom: none;
}

.emptystate-header {
  margin-top: 10px;
}

.applications {
  background-color: #4B49AC;
  height: 187px;
}

.applications .dropdown {
  margin-left: 280px;
}

.application-label {
 font-size: 18px;
 font-weight: 500;
}

.application-count {
  font-size: 29px;
  font-weight: 500;
}

.dashboard-icon {
  position: absolute;
  margin-left: 5px;
  margin-top: 8px;
}

.application-icon {
  position: absolute;
  margin-left: 215px;
  margin-top: 55px;
}
